import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import FacebookButton from "@components/Login/Facebook/FacebookButton.vue";
import { onBeforeMount } from "vue";
import { createFacebookService, FACEBOOK_DEFAULT_VERSION, FACEBOOK_DEFAULT_SCOPE, FACEBOOK_DEFAULT_FIELDS, UserMailMissingException } from "@ts/Login/facebook_service";
export default /*@__PURE__*/ _defineComponent({
    __name: 'FacebookHandler',
    props: {
        appId: {
            type: String,
            required: true
        },
        emailExpected: {
            type: String,
            default: ''
        },
        allowsAccountCreation: {
            type: Boolean,
            default: false
        },
        version: {
            type: String,
            default: FACEBOOK_DEFAULT_VERSION
        },
        scope: {
            type: String,
            default: FACEBOOK_DEFAULT_SCOPE
        },
        fields: {
            type: String,
            default: FACEBOOK_DEFAULT_FIELDS
        },
    },
    emits: ['successful', 'failure', 'loginStarted'],
    setup(__props, { emit: __emit }) {
        const facebookService = createFacebookService();
        const props = __props;
        const emit = __emit;
        onBeforeMount(async () => facebookService.initSdk({
            appId: props.appId,
            version: props.version
        }));
        const login = async () => {
            emit('loginStarted', 'facebook');
            try {
                const options = {
                    scope: props.scope,
                    fields: props.fields,
                    emailExpected: props.emailExpected,
                    createSSOUser: props.allowsAccountCreation,
                };
                const response = await facebookService.login(options);
                emit('successful', response);
            }
            catch (error) {
                if (error instanceof UserMailMissingException) {
                    await facebookService.updateUserInfoCache(error.user);
                    facebookService.redirect('/user/fbGiveUsEmail');
                    return;
                }
                emit('failure', error);
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(FacebookButton, { onClick: login }));
        };
    }
});
