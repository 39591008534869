import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import ZButton from "@components/Core/ZButton.vue";
import FacebookSVG from '@icons/social/fb.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FacebookButton',
    props: {
        variety: {
            type: String,
            default: 'cyclops',
            validator: (value) => {
                // You can add more variety types here
                return [
                    'optimus',
                    'robin',
                    'phoenix',
                    'cyclops',
                    'rogue',
                    'love',
                ].includes(value);
            },
        },
        mode: {
            type: String,
            default: 'light',
            validator: (value) => {
                // You can add more modes types here
                return [
                    'light',
                    'dark',
                ].includes(value);
            },
        },
        wide: {
            type: String,
            default: 'never',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        rounded: {
            type: String,
            default: 'always',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
    },
    emits: ['click'],
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(ZButton, {
                rounded: props.rounded,
                variety: props.variety,
                mode: props.mode,
                wide: __props.wide,
                class: "facebook-login",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (_ctx.$emit('click')), ["prevent"]))
            }, {
                icon: _withCtx(() => [
                    _createVNode(_unref(FacebookSVG), { class: "facebook-icon" })
                ]),
                _: 1
            }, 8, ["rounded", "variety", "mode", "wide"]));
        };
    }
});
