import { getQueryParam, redirect, post } from "@ts/Util/http";
import { currentUserLocale, isoCountryCode } from "@ts/Util/i18n";
import { csrfToken } from "@ts/Util/security";
import { readPageData } from "@ts/Util/page";
import { getMixpanelDistinctID } from '@ts/Util/analytics';
export const MIN_PASSWORD_COMPOSITION_COMBINATION = 2;
export const MIN_PASSWORD_LENGTH = 10;
export const REGEX_PASSWORD_COMBINATIONS = {
    upperCase: /[A-Z]/,
    lowerCase: /[a-z]/,
    numeric: /[0-9]/,
    symbol: /\W/
};
export const createAuthService = () => {
    const locale = currentUserLocale() ?? 'en-US';
    const countryISO = isoCountryCode();
    const fetchConfig = {
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': csrfToken(),
        },
    };
    return {
        login: (user, recaptchaResponse) => {
            const data = new FormData();
            data.append('data[User][username]', user.email);
            data.append('data[User][password]', user.password);
            data.append('g-recaptcha-response', recaptchaResponse);
            if (user.remember) {
                data.append('data[remember_username]', user.remember.toString());
            }
            return new Promise((resolve, reject) => {
                fetch(`/${locale}/login`, { ...fetchConfig, body: data })
                    .then(async (response) => {
                    if (!response.ok) {
                        let body = {
                            success: false,
                            error: null
                        };
                        try {
                            body = await response.json();
                        }
                        catch (e) {
                            console.error(e);
                        }
                        reject(body);
                        return;
                    }
                    resolve(response);
                })
                    .catch((error) => reject(error));
            });
        },
        signUp: (user, recaptchaResponse, source) => {
            const body = {
                ...user,
                source,
                countryISO,
                'get_newsletter': true,
                'terms_of_use': true,
                'g-recaptcha-response': recaptchaResponse,
                'user_source': getQueryParam('user_source'),
                'mixpanelDistinctID': getMixpanelDistinctID(),
            };
            return new Promise((resolve, reject) => {
                post(`/${locale}/user/signup`, csrfToken(), body)
                    .then(async (response) => {
                    const body = await response.json();
                    if (!response.ok) {
                        reject(body);
                        return;
                    }
                    resolve(body);
                })
                    .catch((error) => reject(error));
            });
        },
        loginByEmail: (payload, recaptchaResponse) => {
            const submitUrl = payload.action === 'link' ? 'user/email_signin' : 'user/forgot_password';
            const data = new FormData();
            data.append('data[User][email_or_username]', payload.email);
            data.append('g-recaptcha-response', recaptchaResponse);
            if (payload.redirect) {
                data.append('redirect', payload.redirect);
            }
            return new Promise((resolve, reject) => {
                fetch(`/${locale}/${submitUrl}`, { ...fetchConfig, body: data })
                    .then(async (response) => {
                    const body = await response.json();
                    if (!response.ok) {
                        reject(body);
                        return;
                    }
                    resolve({ response, body });
                })
                    .catch((error) => reject(error));
            });
        },
        emailLookup: (email, lookupEndpoint = '') => {
            const body = {
                'email': email,
            };
            lookupEndpoint = lookupEndpoint ? lookupEndpoint : `/${locale}/training_registration/email_lookup`;
            return new Promise((resolve, reject) => {
                post(lookupEndpoint, csrfToken(), body)
                    .then(async (response) => {
                    const data = await response.json();
                    if (!response.ok) {
                        reject(data);
                        return;
                    }
                    resolve(data);
                })
                    .catch((error) => reject(error));
            });
        },
        logindataForTrainingRegistration: (email) => {
            return new Promise((resolve, reject) => {
                post(`/${locale}/training/login_data`, csrfToken(), { email })
                    .then(async (response) => {
                    const data = await response.json();
                    if (!response.ok) {
                        reject(data);
                        return;
                    }
                    resolve(data);
                })
                    .catch((error) => reject(error));
            });
        },
        ssoUsedByUser: (email) => {
            return new Promise((resolve, reject) => {
                post(`/${locale}/user/ssoUsedByUser`, csrfToken(), { email })
                    .then(async (response) => {
                    const data = await response.json();
                    if (!response.ok) {
                        reject(data);
                        return;
                    }
                    resolve(data);
                })
                    .catch((error) => reject(error));
            });
        },
        isPasswordValid: (password) => {
            const countPasswordComposition = Object
                .values(REGEX_PASSWORD_COMBINATIONS)
                .reduce((counter, regex) => {
                if (regex.test(password)) {
                    return ++counter;
                }
                return counter;
            }, 0);
            return countPasswordComposition >= MIN_PASSWORD_COMPOSITION_COMBINATION && password.length >= MIN_PASSWORD_LENGTH;
        },
        redirect: (urlFragment = '/') => redirect(getQueryParam('redirect') ?? urlFragment),
        getUserSync: () => readPageData('user') || {}
    };
};
