import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "input-formula__suggestion" };
const _hoisted_2 = { class: "input-formula__list" };
import { computed } from "vue";
import { REGEX_PASSWORD_COMBINATIONS } from "@ts/Login/auth_service";
export default /*@__PURE__*/ _defineComponent({
    __name: 'PasswordRequirements',
    props: {
        value: {
            type: String,
            required: true,
        },
        t: {
            type: Object,
            default: () => ({
                useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
                abcCapitalized: 'ABC',
                abcLowerCase: 'abc',
                numeric: '123',
                symbols: '!@%'
            })
        },
        arrowPosition: {
            type: String,
            default: () => 'none'
        },
    },
    setup(__props) {
        const { upperCase, lowerCase, numeric, symbol } = REGEX_PASSWORD_COMBINATIONS;
        const props = __props;
        const createRequirement = (label, meets) => ({ label, meets });
        const requirements = computed(() => [
            createRequirement(props.t.abcCapitalized, upperCase.test(props.value)),
            createRequirement(props.t.abcLowerCase, lowerCase.test(props.value)),
            createRequirement(props.t.numeric, numeric.test(props.value)),
            createRequirement(props.t.symbols, symbol.test(props.value)),
        ]);
        const hasArrow = computed(() => props.arrowPosition !== 'none');
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    'input-formula': true,
                    'input-formula__arrow': hasArrow.value,
                    [`input-formula__arrow__${props.arrowPosition}`]: hasArrow.value,
                })
            }, [
                _createElementVNode("p", _hoisted_1, _toDisplayString(props.t.useAtLeastTwo), 1),
                _createElementVNode("ul", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(requirements.value, ({ label, meets }, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                            key: `label-${index}`,
                            class: _normalizeClass({
                                'input-formula__list__item': true,
                                'input-formula__met-requirement': meets
                            })
                        }, _toDisplayString(label), 3));
                    }), 128))
                ])
            ], 2));
        };
    }
});
