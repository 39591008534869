import { getMixpanelDistinctID } from "@ts/Util/analytics";
import { post } from "@ts/Util/http";
import { currentUserLocale } from "@ts/Util/i18n";
import { csrfToken } from "@ts/Util/security";
export const createGoogleService = (options, successCallback, errorCallback) => {
    const _window = window;
    const locale = currentUserLocale() ?? 'en-US';
    if (_window.google === undefined) {
        throw 'Missing Google SDK, please load in the view';
    }
    const client = _window.google.accounts.oauth2.initCodeClient({
        client_id: options.clientID,
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        error_callback: ({ type }) => errorCallback(type),
        callback: (event) => {
            if (!event.code) {
                return;
            }
            const body = {
                code: event.code,
                emailExpected: options.emailExpected ?? '',
                createSSOUser: options.createSSOUser ?? false,
                mixpanelDistinctID: getMixpanelDistinctID(),
            };
            post(`/${locale}/user/loginWithGoogleCredentials`, csrfToken(), body)
                .then(async (response) => {
                const jsonResponse = await response.json();
                jsonResponse.success ? successCallback(jsonResponse) : errorCallback(jsonResponse);
            })
                .catch(error => errorCallback(error));
        }
    });
    const createProfile = (profile) => {
        const body = {
            createSSOUser: true,
            mixpanelDistinctID: getMixpanelDistinctID(),
            firstName: profile.firstName,
            lastName: profile.lastName
        };
        post(`/${locale}/user/signUpWithGoogleCredentials`, csrfToken(), body)
            .then(async (response) => {
            const jsonResponse = await response.json();
            jsonResponse.success ? successCallback(jsonResponse) : errorCallback(jsonResponse);
        })
            .catch(error => errorCallback(error));
    };
    return {
        login: () => client.requestCode(),
        createProfile: createProfile,
    };
};
