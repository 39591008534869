import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import GoogleButton from '@components/Login/Google/GoogleButton.vue';
import { createGoogleService } from '@ts/Login/google_service';
import { redirect } from "@ts/Util/http";
export default /*@__PURE__*/ _defineComponent({
    __name: 'GoogleHandler',
    props: {
        clientId: {
            type: String,
            required: true
        },
        emailExpected: {
            type: String,
            default: ''
        },
        allowsAccountCreation: {
            type: Boolean,
            default: false
        },
    },
    emits: ['successful', 'failure', 'loginStarted'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const googleServiceOptions = {
            clientID: props.clientId,
            emailExpected: props.emailExpected,
            createSSOUser: props.allowsAccountCreation
        };
        const googleService = createGoogleService(googleServiceOptions, (response) => emit('successful', response), (error) => {
            if (error && error.errorType === 'missing_user_profile') {
                redirect('/user/googleGiveUsProfile');
            }
            emit('failure', error);
        });
        const handleSignIn = () => {
            emit('loginStarted', 'google');
            googleService.login();
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(GoogleButton, {
                onClick: _cache[0] || (_cache[0] = ($event) => (handleSignIn()))
            }));
        };
    }
});
