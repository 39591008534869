import { post } from "@ts/Util/http";
import { currentUserLocale } from "@ts/Util/i18n";
import { csrfToken } from "@ts/Util/security";
import { redirect } from "@ts/Util/http";
import { getMixpanelDistinctID } from "@ts/Util/analytics";
export const createAppleService = (options) => {
    const _window = window;
    const locale = currentUserLocale() ?? 'en-US';
    if (_window.AppleID === undefined) {
        throw 'Missing AppleID SDK, please load in the view';
    }
    _window.AppleID.auth.init({
        clientId: options.clientID,
        scope: 'name email',
        redirectURI: window.location.origin + '/apple/login_callback',
        state: options.state,
        usePopup: true
    });
    const loginByZumba = (code, token, options) => {
        const body = {
            token,
            code,
            mixpanelDistinctID: getMixpanelDistinctID(),
            ...options
        };
        return new Promise((resolve, reject) => {
            post(`/${locale}/user/loginWithAppleCredentials`, csrfToken(), body)
                .then(async (response) => {
                const jsonResponse = await response.json();
                jsonResponse.success ? resolve(jsonResponse) : reject({ jsonResponse, body });
            });
        });
    };
    return {
        redirect,
        login: (options) => {
            return new Promise((resolve, reject) => {
                _window.AppleID.auth.signIn()
                    .then((event) => {
                    const { code, id_token } = event.authorization;
                    const { name } = event.user ?? {};
                    return loginByZumba(code, id_token, {
                        ...name ?? {},
                        ...options,
                    });
                })
                    .then((response) => resolve(response))
                    .catch(({ jsonResponse, ...response }) => {
                    if (jsonResponse?.error === 'missing_user_profile') {
                        reject(new UserMissingException());
                        return;
                    }
                    if (['popup_closed_by_user', 'user_cancelled_authorize'].includes(response?.error)) {
                        reject('Canceled by user');
                        return;
                    }
                    reject(new AppleLoginException(jsonResponse.error, response));
                });
            });
        },
        createProfile: (firstName, lastName) => {
            return new Promise((resolve, reject) => {
                const body = {
                    firstName,
                    lastName,
                    mixpanelDistinctID: getMixpanelDistinctID()
                };
                post(`/${locale}/user/signUpWithAppleCredentials`, csrfToken(), body)
                    .then(async (response) => {
                    const jsonResponse = await response.json();
                    jsonResponse.success ? resolve(jsonResponse) : reject(jsonResponse);
                });
            });
        },
    };
};
export class AppleLoginException extends Error {
    constructor(message, body) {
        super(message ?? 'Error while trying to login with Apple');
        this._body = body;
    }
    get body() {
        return this._body;
    }
}
export class UserMissingException extends Error {
    constructor(message = 'User is missing') {
        super(message);
    }
}
