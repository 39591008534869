import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
import { onMounted, reactive } from "vue";
import { loadScript } from "@ts/Util/page";
export default /*@__PURE__*/ _defineComponent({
    __name: 'GoogleRecaptcha',
    props: {
        siteKey: {
            type: String,
            required: true
        },
        identifier: {
            type: String,
            default: 'g-recaptcha'
        },
        size: {
            type: String,
            default: 'invisible'
        },
        badge: {
            type: String,
            default: 'inline'
        },
    },
    emits: ['successful', 'failure', 'canceled', 'loaded'],
    setup(__props, { expose: __expose, emit: __emit }) {
        const _window = window;
        const props = __props;
        const state = reactive({
            executed: false,
        });
        const emit = __emit;
        onMounted(async () => {
            _window.recaptchaCallback = () => {
                _window.grecaptcha.render(props.identifier, {
                    sitekey: props.siteKey,
                    size: props.size,
                    badge: props.badge,
                    callback: (token) => emit('successful', token),
                    'error-callback': (error) => emit('failure', error),
                    'expired-callback': () => emit('canceled'),
                });
                emit('loaded');
            };
            await loadScript('https://www.recaptcha.net/recaptcha/api.js?onload=recaptchaCallback&render=explicit');
        });
        __expose({
            execute: async () => {
                if (state.executed) {
                    _window.grecaptcha.reset();
                }
                state.executed = true;
                return _window.grecaptcha.execute();
            },
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("input", {
                id: props.identifier,
                type: "hidden",
                class: "g-recaptcha"
            }, null, 8, _hoisted_1));
        };
    }
});
