import { defineComponent as _defineComponent } from 'vue';
import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "input-password" };
const _hoisted_2 = { class: "input-password__input-right-slot" };
const _hoisted_3 = {
    key: 1,
    class: "input-password__error"
};
const _hoisted_4 = {
    key: 2,
    class: "input-password__hint"
};
import ZInput from '../Core/ZInput.vue';
import { ref, watch, computed, reactive } from 'vue';
import PasswordRequirements from "@components/Core/PasswordRequirements.vue";
import SVGCheckRounded from '@icons/check-rounded.svg';
import { createAuthService } from "@ts/Login/auth_service";
export default /*@__PURE__*/ _defineComponent({
    __name: 'PasswordInput',
    props: {
        t: {
            type: Object,
            default: () => ({
                useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
                abcCapitalized: 'ABC',
                abcLowerCase: 'abc',
                numeric: '123',
                symbols: '!@%',
                show: 'Show',
                hide: 'Hide',
                hint: '',
            })
        },
        label: {
            type: String,
            default: "Choose password"
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            default: 'password'
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        maxlength: {
            type: Number,
            default: null
        },
        max: {
            type: [Number, String],
            default: null,
        },
        min: {
            type: [Number, String],
            default: null,
        },
        error: {
            type: String,
            default: ''
        },
        showRequired: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: true
        },
        showRequirements: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: 'on'
        }
    },
    emits: ['invalid', 'update:model-value', 'focus', 'blur'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const passwordValue = ref(props.modelValue);
        const authService = createAuthService();
        const emit = __emit;
        const onInput = (event) => {
            passwordValue.value = event.target.value;
            emit('update:model-value', passwordValue.value);
        };
        const type = computed(() => state.isPasswordHide ? 'password' : 'text');
        const toggleLabel = computed(() => state.isPasswordHide ? props.t.show : props.t.hide);
        const state = reactive({
            isPasswordValid: false,
            isPasswordHide: true
        });
        const toggleView = () => state.isPasswordHide = !state.isPasswordHide;
        watch(() => passwordValue.value, (newPassword) => {
            state.isPasswordValid = authService.isPasswordValid(newPassword);
        }, { immediate: true });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (__props.showRequirements)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (passwordValue.value)
                            ? _withDirectives((_openBlock(), _createBlock(PasswordRequirements, {
                                key: 0,
                                t: props.t,
                                "arrow-position": "bottom-left",
                                value: passwordValue.value
                            }, null, 8, ["t", "value"])), [
                                [_vShow, !state.isPasswordValid]
                            ])
                            : _createCommentVNode("", true)
                    ], 64))
                    : _createCommentVNode("", true),
                _createVNode(ZInput, {
                    modelValue: passwordValue.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((passwordValue).value = $event)),
                    name: __props.name,
                    type: type.value,
                    label: __props.label,
                    "show-label": __props.showLabel,
                    disabled: __props.disabled,
                    required: __props.required,
                    placeholder: __props.placeholder,
                    maxlength: __props.maxlength,
                    max: __props.max,
                    min: __props.min,
                    "show-required": __props.showRequired,
                    autocomplete: __props.autocomplete,
                    onInput: onInput,
                    onFocus: _cache[1] || (_cache[1] = (event) => emit('focus', event)),
                    onBlur: _cache[2] || (_cache[2] = (event) => emit('blur', event)),
                    onInvalid: _cache[3] || (_cache[3] = (event) => emit('invalid', event))
                }, _createSlots({ _: 2 }, [
                    (__props.showToggle || __props.showRequirements)
                        ? {
                            name: "right-slot",
                            fn: _withCtx(() => [
                                _createElementVNode("div", _hoisted_2, [
                                    (__props.showRequirements)
                                        ? (_openBlock(), _createBlock(_unref(SVGCheckRounded), {
                                            key: 0,
                                            class: _normalizeClass({
                                                'input-password__input-check': true,
                                                'input-password__input-check__active': state.isPasswordValid,
                                            })
                                        }, null, 8, ["class"]))
                                        : _createCommentVNode("", true),
                                    (__props.showToggle)
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 1,
                                            class: "input-password__password-toggle",
                                            onClick: toggleView
                                        }, _toDisplayString(toggleLabel.value), 1))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            key: "0"
                        }
                        : undefined
                ]), 1032, ["modelValue", "name", "type", "label", "show-label", "disabled", "required", "placeholder", "maxlength", "max", "min", "show-required", "autocomplete"]),
                (__props.error)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(__props.error), 1))
                    : _createCommentVNode("", true),
                (props.t.hint && !__props.error)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(props.t.hint), 1))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
