import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import ZButton from '@components/Core/ZButton.vue';
import GoogleSVG from '@icons/social/google.svg';
export default /*@__PURE__*/ _defineComponent({
    __name: 'GoogleButton',
    emits: ['click'],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(ZButton, {
                rounded: "always",
                variety: "cyclops",
                "super-compact": "never",
                compact: "never",
                wide: "always",
                class: "google-button",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event) => (emit('click')), ["prevent"]))
            }, {
                icon: _withCtx(() => [
                    _createVNode(_unref(GoogleSVG), { class: "google-svg" })
                ]),
                _: 1
            }));
        };
    }
});
