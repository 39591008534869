import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import AppleButton from '@components/Login/Apple/AppleButton.vue';
import { createAppleService, UserMissingException } from '@ts/Login/apple_service';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppleHandler',
    props: {
        clientId: {
            type: String,
            required: true
        },
        redirectUri: {
            type: String,
            required: true
        },
        emailExpected: {
            type: String,
            default: ''
        },
        allowsAccountCreation: {
            type: Boolean,
            default: false
        },
        state: {
            type: String,
            default: 'login'
        }
    },
    emits: ['successful', 'failure', 'loginStarted'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const appleService = createAppleService({
            clientID: props.clientId,
            redirectURI: props.redirectUri,
            state: props.state,
        });
        const handleSignIn = async () => {
            emit('loginStarted', 'apple');
            try {
                const options = {
                    emailExpected: props.emailExpected,
                    createSSOUser: props.allowsAccountCreation
                };
                const response = await appleService.login(options);
                emit('successful', response);
            }
            catch (error) {
                if (error instanceof UserMissingException) {
                    appleService.redirect('/user/appleGiveUsProfile');
                    return;
                }
                emit('failure', error);
            }
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(AppleButton, {
                onClick: _cache[0] || (_cache[0] = ($event) => (handleSignIn()))
            }));
        };
    }
});
